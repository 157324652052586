//登录页面,侧边栏接口
import { request } from "./request"

export function goLogin(msg) {
    return request({
        url: "api/alogin/",
        method: "get",
        params: msg
    })

}

export function isdealer(data) {
    return request({
        url: "v3/022/",
        method: "post",
        data
    })
}


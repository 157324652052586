<template>
  <div class="container">
    <div class="Login">
      <div class="login_title">双重预防管理系统</div>
      <!-- <button @click="add">跳转</button> -->
      <el-form ref="form" :model="userFormData" label-width="80px">
        <el-form-item label="用户名: ">
          <el-input v-model="userFormData.username" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密  码: ">
          <el-input v-model="userFormData.password" show-password placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-button @click="toLogin" type="primary" class="loginBtn">登录</el-button><br>

        <!-- <i style="font-size:12px; float: right;margin-right:10px">

          <a href="http://yxrzypt.com:8083/#/Login">回到旧版</a>
        </i> -->
      </el-form>
    </div>
    <div style="padding-top: 910px; text-align: center;width:100%">
      <div>
        2019-2026
        <a href="https://beian.miit.gov.cn/#/Integrated/index"> 豫ICP备18042992号-1</a>
        <img src="http://www.aqjkpt.com:9999/ba.png" alt="">
        豫公网备案 41010602000212号
      </div>
    </div>
  </div>
</template>

<script>
import {
  goLogin,
  isdealer
} from "../request/login"
export default {
  name: "Login",
  data() {
    return {
      userFormData: {
        username: "",
        password: ''
      },
      username: ""
    };
  },
  methods: {
    add() {
      this.$router.push("/Home");
    },
    toLogin() {
      goLogin(this.userFormData).then((res) => {
        console.log(res, '登录');

        if (res.data.code == 200) {
          if (res.data.user.rank == 4) {
            this.$router.push('/Upload')
          } else {
            this.$router.push('/visualization')
          }
          // this.$router.push('/Home')
          const companys = res.data.user.companys == null ? 0 : res.data.user.companys
          const corporations = res.data.user.corporations == null ? 0 : res.data.user.corporations
          // this.username = res.data.user.name
          window.localStorage.setItem('username', res.data.user.name)
          window.localStorage.setItem('TOKEN', res.data.token)
          window.localStorage.setItem('rank', res.data.user.rank)
          window.localStorage.setItem('companys', companys)
          window.localStorage.setItem('corporations', corporations)
          window.localStorage.setItem('uid', res.data.user.uid)
          if (localStorage.getItem('rank') == '1') {
            window.localStorage.setItem('project', companys)
          } else if (localStorage.getItem('rank') == '2' || localStorage.getItem('rank') == '3') {
            window.localStorage.setItem('project', corporations)
          }
          this.isdealer(res.data.user.uid)
        } else if (res.data.code == 404) {
          this.$message.error(res.data.mes);
        }
      })
    },
    async isdealer(id) {
      let msg = {
        User_identity: id
      }
      const res = await isdealer(msg)
      console.log(res, '121111111111');
      localStorage.setItem('isdealer', res.data.data)
      if (localStorage.getItem('rank') == '1') {
        localStorage.setItem('type', 'firm')
      } else if (localStorage.getItem('rank') == '2' || localStorage.getItem('rank') == '3') {
        localStorage.setItem('type', 'pro')
      }
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  background: url('http://www.aqjkpt.com:9999/uniapp/new/web/1.jpg') no-repeat;
  background-size: 100% 100%;

}

.Login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 10px 5px 3px 3px rgba(0, 0, 0, 0.3);
  /*默认为外部阴影*/
  background-color: white;

  .login_title {
    width: 100%;
    height: 80px;
    color: black;
    font-weight: 700;
    font-size: 20px;
    line-height: 80px;
    text-align: center;
  }

  .el-form-item__label {
    color: white;
  }

  // position: relative;
  // background-color:  rgba(143, 138, 138, 0.2);
  // transform: translate(-50%, -50%);
  width: 400px;
  height: 300px;
  border-radius: 20px;

}

.Login .el-input {
  // padding-top: 10px;
  width: 80%;
}

.loginBtn {
  margin-top: 10px;
  margin-left: 90px;
  width: 225px;
}
</style>